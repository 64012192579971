<template>
  <v-card-text>
    <MessageBox ref="msgBox" />
    <SetString ref="createFolder" />
    <FileUploader
      ref="fileUploader"
      @uploadCompleted="handleUploadCompleted"
      v-model="fileUploader"
    />
    <div class="elevation-1">
      <div ref="crumb_bar" class="ml-2 text-left body-1 crumb-bar">
        <span
          class="crumb"
          @click="onClickCrumb(crumbs[index])"
          v-for="(child, index) in crumbs"
          :key="index"
          :item="child"
          >{{ child.root ? "Storage" : child.name }} >
        </span>
      </div>
      <splitpanes
        v-if="!isMobile"
        style="min-height: 600px;"
        class="default-theme"
      >
        <pane size="20" min-size="20">
          <div class="text-left file-toolbar">
            <v-tooltip v-if="$store.state.hasRight('40')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  dark
                  class="toolbar-icon"
                  v-on="on"
                  icon
                  @click="onCreateFolder"
                  :disabled="!isFolderSelected()"
                  style="text-align:center"
                  href="#"
                  ><v-icon size="28" color="yellow darken-3"
                    >mdi-folder-plus</v-icon
                  ></v-btn
                ></template
              ><span>{{ $t("fileview-addfolder") }}</span></v-tooltip
            >
            <v-tooltip v-if="$store.state.hasRight('41')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  dark
                  class="toolbar-icon"
                  v-on="on"
                  icon
                  @click="onRemoveFolder"
                  :disabled="!isFolderSelected() || isRootItem()"
                  href="#"
                  ><v-icon size="28" color="#f03f3b"
                    >mdi-folder-remove</v-icon
                  ></v-btn
                ></template
              ><span>{{ $t("fileview-remove-folder") }}</span></v-tooltip
            >
          </div>
          <div>
            <div class="folders_scroll_area">
              <FoldersItem
                ref="root_folder"
                :folderOnly="true"
                :disabled="isFetching"
                :fetchCallback="fetchCallback"
                :selectCallback="selectCallback"
                :item="treeData"
                :indent="1"
              ></FoldersItem>
            </div>
          </div>
        </pane>
        <pane min-size="20">
          <div class="text-left file-toolbar">
            <v-tooltip v-if="selectionMode" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  dark
                  class="toolbar-icon"
                  v-on="on"
                  icon
                  @click="onSelectFile"
                  :disabled="!isFileSelected()"
                  style="text-align:center"
                  href="#"
                  color="success"
                  ><v-icon size="28">mdi-check-circle-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("fileview-select") }}</span></v-tooltip
            >
            <v-tooltip
              v-if="$store.state.hasRight('39') && !selectionMode"
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  dark
                  class="toolbar-icon"
                  v-on="on"
                  icon
                  :download="downloadFileHint"
                  target="_blank"
                  :href="downloadFilePath"
                  :disabled="!isFileSelected()"
                  style="text-align:center"
                  color="success"
                  ><v-icon size="28">mdi-download</v-icon></v-btn
                ></template
              ><span>{{ $t("fileview-download") }}</span></v-tooltip
            >
            <v-tooltip v-if="$store.state.hasRight('37')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  dark
                  class="toolbar-icon"
                  v-on="on"
                  icon
                  @click="onUploadFile"
                  :disabled="!isFolderSelected()"
                  style="text-align:center"
                  href="#"
                  color="orange"
                  ><v-icon size="28">mdi-upload</v-icon></v-btn
                ></template
              ><span>{{ $t("fileview-upload") }}</span></v-tooltip
            >
            <v-tooltip v-if="$store.state.hasRight('38')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  dark
                  class="toolbar-icon"
                  v-on="on"
                  icon
                  @click="onRemoveFile"
                  :disabled="!isFileSelected()"
                  href="#"
                  color="#f03f3b"
                  ><v-icon size="28">mdi-delete-circle-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("fileview-remove-file") }}</span></v-tooltip
            >
          </div>
          <div class="file-manager-header">
            <v-row dense class="file-header">
              <v-col cols="7" class="text-left pl-4">{{
                $t("fileview-tbl-name")
              }}</v-col>
              <v-col cols="3" class="text-center">{{
                $t("fileview-tbl-modified")
              }}</v-col>
              <v-col cols="2" class="text-center">{{
                $t("fileview-tbl-size")
              }}</v-col>
            </v-row>
            <div
              class="files_scroll_area"
              v-if="this.selectedFolder"
              :key="this.filesViewKey"
            >
              <FileItem
                @itemSelected="fileSelected($event)"
                v-for="(child, index) in this.selectedFolder.item.files"
                :key="index"
                :item="child"
              ></FileItem>
            </div>
          </div>
        </pane>
      </splitpanes>

      <div v-if="isMobile" style="min-height: 600px;">
        <div class="text-left file-toolbar-mobile">
          <v-tooltip v-if="$store.state.hasRight('40')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                dark
                class="toolbar-icon-mobile"
                v-on="on"
                icon
                @click="onCreateFolder"
                :disabled="!isFolderSelected()"
                style="text-align:center"
                href="#"
                ><v-icon color="yellow darken-3">mdi-folder-plus</v-icon></v-btn
              ></template
            ><span>{{ $t("fileview-addfolder") }}</span></v-tooltip
          >
          <v-tooltip v-if="$store.state.hasRight('41')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                dark
                class="toolbar-icon-mobile"
                v-on="on"
                icon
                @click="onRemoveFolder"
                :disabled="!isFolderSelected() || isRootItem()"
                href="#"
                ><v-icon color="warning">mdi-folder-remove</v-icon></v-btn
              ></template
            ><span>{{ $t("fileview-remove-folder") }}</span></v-tooltip
          >
          <v-tooltip v-if="selectionMode" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                dark
                class="toolbar-icon-mobile"
                v-on="on"
                icon
                @click="onSelectFile"
                :disabled="!isFileSelected()"
                style="text-align:center"
                href="#"
                color="success"
                ><v-icon>mdi-check-circle-outline</v-icon></v-btn
              ></template
            ><span>{{ $t("fileview-select") }}</span></v-tooltip
          >
          <v-tooltip v-if="$store.state.hasRight('39') && !selectionMode" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                dark
                class="toolbar-icon-mobile"
                v-on="on"
                icon
                :download="downloadFileHint"
                :href="downloadFilePath"
                :disabled="!isFileSelected()"
                style="text-align:center"
                color="accent"
                ><v-icon>mdi-download</v-icon></v-btn
              ></template
            ><span>{{ $t("fileview-download") }}</span></v-tooltip
          >
          <v-tooltip v-if="$store.state.hasRight('37')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                dark
                class="toolbar-icon-mobile"
                v-on="on"
                icon
                @click="onUploadFile"
                :disabled="!isFolderSelected()"
                style="text-align:center"
                href="#"
                color="accent"
                ><v-icon>mdi-upload</v-icon></v-btn
              ></template
            ><span>{{ $t("fileview-upload") }}</span></v-tooltip
          >
          <v-tooltip v-if="$store.state.hasRight('38')" bottom
            ><template v-slot:activator="{ on }"
              ><v-btn
                dark
                class="toolbar-icon-mobile"
                v-on="on"
                icon
                @click="onRemoveFile"
                :disabled="!isFileSelected()"
                href="#"
                color="warning"
                ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
              ></template
            ><span>{{ $t("fileview-remove-file") }}</span></v-tooltip
          >
        </div>
        <div class="folders_scroll_area">
          <FoldersItem
            ref="root_folder"
            :fileSelectCallback="folderFileSelectCallback"
            :folderOnly="false"
            :disabled="isFetching"
            :fetchCallback="fetchCallback"
            :selectCallback="selectCallback"
            :item="treeData"
            :indent="1"
          ></FoldersItem>
        </div>
      </div>
    </div>
  </v-card-text>
</template>

<style>
.splitpanes__splitter {
  width: 6px !important;
  background-color: #eeeeee !important;
}

.file-divider {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.files_scroll_area {
  overflow: scroll;
  max-height: 600px;
  padding-top: 10px;
}

.folders_scroll_area {
  overflow-y: auto;
  overflow-x : hidden;
  max-height: 600px;
  border-top: 4px solid #eeeeee;
  padding-top: 2px;
  padding-bottom: 12px;
}

.file-manager-card {
  margin-left: 10px;
  margin-right: 10px;
}

.file-manager-header {
  border-top: 1px solid #dddddd;
  background-color: white;
  color: black;
}

.file-header {
  position: relative;
  top: 3px;
  background-color: #807161;
  color: white;
  border-bottom: 1px solid lightgray;
  line-height: 24px;
}

.item {
  cursor: pointer;
}

.file-toolbar {
  height: 36px;
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 36px;
  background-color: #875b35;
}

.file-toolbar-mobile {
  height: 36px;
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 36px;
  background-color: #875b35;
}

.toolbar-icon-mobile {
  margin-left: 7px;
  margin-right: 7px;
}

.toolbar-icon {
  margin-left: 15px;
  margin-right: 15px;
}

.crumb-bar {
  padding-top: 10px;
  padding-bottom: 10px;
}

.crumb {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}
</style>

<script>
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

import FileUploader from "../components/FileUploader.vue";
import FoldersItem from "../components/FoldersItem.vue";
import FileItem from "../components/FileItem.vue";
import SetString from "../components/SetString.vue";
import MessageBox from "../components/MessageBox.vue";
import fileManager from "../apis/filemanager.js";
import localStorageService from "../services/localStorageService";
import config from "../config";

const path = require("path");

export default {
  components: {
    FoldersItem,
    FileItem,
    SetString,
    MessageBox,
    FileUploader,
    Splitpanes,
    Pane,
  },

  props: {
    path: String,
    selectionMode: Boolean,
  },

  watch: {
    selectedFolder: {
      handler() {
        this.updateCrumbs();
      },
    },
  },

  computed: {
    selectedFolderPath: function() {
      if (!this.selectedFolder) return "Documenti";
      let items = this.selectedFolder.item.fullName.split("/").slice(1);
      let out = "";
      for (let x in items) {
        if (items[x]) {
          if (x != 0) out += " > ";
          out += items[x];
        }
      }
      return out;
    },

    downloadFilePath: function() {
      if (!this.selectedFile) return "#";
      const token = localStorageService.getAccessToken();
      let filePath =
        config.apiEndPoint +
        "/filemanager/download?file=" +
        encodeURIComponent(this.selectedFile.item.fullName) +
        "&token=" +
        encodeURIComponent(token);
      return filePath;
    },

    downloadFileHint: function() {
      if (!this.selectedFile) return "";
      let filename = path.basename(this.selectedFile.item.fullName);
      return filename;
    },
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  data() {
    return {
      isMobile: false,

      treeData: {},
      selectedFolder: null,
      selectedFile: null,
      isFetching: false,
      fileUploader: false,
      filesViewKey: 1,

      crumbs: [],
    };
  },

  mounted() {
    this.treeData = {
      name: this.path.substr(0, this.path.length - 1),
      fullName: this.path,
      size: null,
      children: [],
      parent: null,
      root: true,
      domItem: null,
    };
    let prefix = "/";
    if (this.treeData.name.indexOf(prefix) == 0) {
      this.treeData.name = this.treeData.name.substr(
        prefix.length,
        this.treeData.name.length
      );
    }

    this.onResize();
    this.updateCrumbs();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    updateCrumbs: function() {
      if (this.selectedFolder) {
        this.crumbs = [];
        let folder = this.selectedFolder.item;
        let firstFolder = true;
        while (folder) {
          this.crumbs = [
            {
              name: folder.name,
              selectable: firstFolder ? false : true,
              item: folder,
            },
            ...this.crumbs,
          ];
          folder = folder.parent;
          if (!folder) this.crumbs[0].root = true;
          firstFolder = false;
        }
      } else {
        this.crumbs = [
          { root: true, name: "Documenti", selectable: false, item: null },
        ];
      }
    },

    onClickCrumb(item) {
      if (item == this.selectedFolder) return;
      if (item && item.selectable && item.item && item.item.domItem)
        item.item.domItem.selectItem();
      else if (item.item == null || item.item.root) {
        this.$refs.root_folder.selectItem();
      }
    },

    folderFileSelectCallback(fileItem) {
      if (this.selectedFile) {
        this.selectedFile.item.isSelected = false;
        this.selectedFile.$forceUpdate();
      }

      if (!fileItem.item.isSelected) {
        fileItem.item.isSelected = true;
        this.selectedFile = fileItem;
        this.selectedFile.$forceUpdate();
      } else {
        fileItem.item.isSelected = false;
        this.selectedFile = null;
      }
    },

    fileSelected(fileItem) {
      if (this.selectedFile) {
        this.selectedFile.item.isSelected = false;
        this.selectedFile.$forceUpdate();
      }

      if (!fileItem.item.isSelected) {
        fileItem.item.isSelected = true;
        this.selectedFile = fileItem;
        this.selectedFile.$forceUpdate();
      } else {
        fileItem.item.isSelected = false;
        this.selectedFile = null;
      }
    },

    onResize() {
      this.isMobile = window.innerWidth < 800;
    },

    handleUploadCompleted: function() {
      this.selectedFolder.fetch();
    },

    isFolderSelected: function() {
      if (this.selectedFolder) return true;
      return false;
    },

    isFileSelected: function() {
      if (this.selectedFile) return true;
      return false;
    },

    isSelectionEmpty: function() {
      if (!this.selectedFile && !this.selectedFolder) return true;
      return false;
    },

    isRootItem: function() {
      if (!this.selectedFolder) return false;
      if (this.selectedFolder.item.root) return true;
      return false;
    },

    onSelectFile: function() {
      if (this.selectedFile) {
        this.$emit("fileSelected", this.selectedFile.item.fullName);
      }
    },

    onDownloadFile: function() {
      if (this.selectedFile) {
        fileManager
          .downloadFile(
            this.selectedFile.item.fullName,
            this.selectedFile.item.name
          )
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }
    },

    onRemoveFile: function() {
      let selectedItem = this.selectedFile;
      if (selectedItem) {
        this.$refs.msgBox
          .show(
            this.$t("fileview-remove-file-text"),
            this.$t("fileview-remove-file-title")
          )
          .then(() => {
            fileManager
              .removeFile(selectedItem.item.fullName)
              .then((result) => {
                if (result) {
                  if (this.selectedFile && this.selectedFolder)
                    this.selectedFolder.fetch();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch(() => {});
      }
    },

    onRemoveFolder: function() {
      let selectedItem = this.selectedFolder;
      if (selectedItem && !selectedItem.item.root) {
        this.$refs.msgBox
          .show(
            this.$t("fileview-remove-folder-text"),
            this.$t("fileview-remove-folder-title")
          )
          .then(() => {
            fileManager
              .removeFile(selectedItem.item.fullName)
              .then((result) => {
                if (result) {
                  this.selectedFolder = null;
                  var index = selectedItem.item.parent.children.indexOf(
                    selectedItem.item
                  );
                  if (index >= 0)
                    selectedItem.item.parent.children.splice(index, 1);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch(() => {});
      }
    },

    onCreateFolder: function() {
      if (this.selectedFolder) {
        this.$refs.createFolder
          .show(
            this.$t("fileview-addfolder-title"),
            this.$t("fileview-addfolder-hint"),
            this.$t("fileview-addfolder-text"),
            this.$t("fileview-addfolder-default"),
            this.selectedFolder,
            true,
            false
          )
          .then((res) => {
            let path = res.context.item.fullName;
            let folder = res.value;
            let fullPath = path + folder + "/";
            if (fullPath != path) {
              fileManager
                .createFolder(fullPath)
                .then((result) => {
                  if (result) {
                    res.context.item.children.push({
                      name: folder,
                      fullName: fullPath,
                      size: 0,
                      parent: res.context.item,
                      children: [],
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch(() => {
            // No action , we reject on cancel
          });
      }
    },

    onUploadFile: function() {
      this.$refs.fileUploader.setPath(
        this.selectedFolder.item,
        this.selectedFolder.item.fullName
      );
      this.fileUploader = true;
    },

    fetchCallback: function(event, callback) {
      if (!event.state) {
        event.item.children = []; // Remove childrens
        this.filesViewKey++;
        callback();
      } else {
        // Fetch childrens
        this.fetchChilds(event.item.fullName)
          .then((result) => {
            event.item.children = [...result.folders, ...result.files];
            event.item.files = result.files;
            event.item.folders = result.folders;
            for (let n = 0; n < event.item.children.length; n++) {
              event.item.children[n].parent = event.item;
            }
            this.filesViewKey++;
            callback();
          })
          .catch((err) => {
            console.log(err);
            callback();
          });
      }
    },

    stackFetchCallback: function(event, callback) {
      // Fetch childrens
      this.fetchChilds(false, event.item.fullName)
        .then((result) => {
          event.item.children = [...result.folders, ...result.files];
          event.item.files = result.files;
          event.item.folders = result.folders;
          for (let n = 0; n < event.item.children.length; n++) {
            event.item.children[n].parent = event.item;
          }
          callback();
        })
        .catch((err) => {
          console.log(err);
          callback();
        });
    },

    stackSelectCallback: function(/*item*/) {},

    selectCallback: function(item) {
      if (this.selectedFolder) {
        this.selectedFolder.item.isSelected = false;
        this.selectedFolder.$forceUpdate();
      }

      if (this.selectedFile) {
        this.selectedFile.item.isSelected = false;
        this.selectedFile.$forceUpdate();
        this.selectedFile = null;
      }

      this.selectedFolder = item;
      this.selectedFolder.item.isSelected = true;
    },

    fetchChilds: function(parent) {
      return new Promise((resolve, reject) => {
        this.isFetching = true;
        fileManager
          .fetchFiles(parent)
          .then((result) => {
            let files = [];
            let folders = [];
            result.folders.sort((a, b) => {
              return a < b;
            });
            result.files.sort((a, b) => {
              return a < b;
            });
            for (let n = 0; n < result.folders.length; n++) {
              let name = result.folders[n].Prefix;
              let fullName = name;
              if (name.indexOf(parent) == 0)
                name = name.substring(parent.length, name.length - 1);
              folders.push({
                name: name,
                fullName: fullName,
                size: null,
                children: [],
              });
            }

            for (let n = 0; n < result.files.length; n++) {
              let name = result.files[n].Key;
              if (name != parent) {
                let fullName = name;
                if (name.indexOf(parent) == 0)
                  name = name.substring(parent.length, name.length);
                files.push({
                  name: name,
                  fullName: fullName,
                  size: result.files[n].Size,
                  modified: result.files[n].LastModified,
                });
              }
            }

            this.isFetching = false;
            resolve({ files: files, folders: folders });
          })
          .catch((err) => {
            console.log(err);
            this.isFetching = false;
            reject(err);
          });
      });
    },
  },
};
</script>
